
header {
    background-color: rgb(12, 12, 12);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    text-align: center;
   
}


.logo img {
   
    height: 100px;
    margin-right: 20px;
    border-radius: 50%;
}

