*{
    font-family: "Amatic SC", sans-serif;
    font-weight: 700;
    font-style: normal;
}

 body {
    background-image: url('./images/background2.jpeg'); 
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    margin: 0; 
    padding: 0; 
  
  }
 
  .app-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
  }
  
  .content {
    flex: 1 ;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }

  @media screen and (max-width: 769px) {
    body {
    background-position: 100%; 
    }
  }