.footer {
    background-color: rgb(12, 12, 12);
    color: white;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}

.footer p {
    color: whitesmoke;
    padding: 0;
}

.footer a {
    color: whitesmoke;
    padding: 0;
}

.footer-logo a {
    width: 2rem;
    height: 2rem;
    
}